import React from 'react'

function Dashboard() {
  return (<>
    <div>Dashboard</div>
    <p>Contains: Cap Table, Securities creation: Equities, Options, Warrants and Convertible instruments.</p>
    <p>Shareholders</p>
    <p>Reports: Captable Summary & Detail, Secondary Transactions, Vesting Report</p>
    <p>Certificate Templates, Board Resolutions, Certificates, Agreements (safe and others, product creation)</p>
    <p>Compliance Form 3921, ASC 718, Rule 701, Form 83b</p>
    <p>409A Valuation (?), Startup Valuation</p>
    <p>Modeling: Waterfall, Round Modeling, Company Comparable</p>
    </>)
}

export default Dashboard