import React from 'react';
import './TabInfo.css';
import { useAuth } from '../../../../context/AuthContext';  
import AccountSideBar from '../../../Functionality/AccountSideBar/AccountSideBar';
import Stepper from '../../../Design/Stepper/Stepper';

function TabInfo() {
  const { user } = useAuth();  

  if (!user) {
    return <div>Loading user data...</div>;
  }

  // Mapping of role to title messages
  const titleMessages = {
    'user': 'Get Accredited To Unlock Investment View',
    'investor': 'Fund Your Account',
    // Add more roles and messages here as needed
    // 'admin': 'Admin Dashboard',
    // 'manager': 'Manage Investments',
  };

  // Get the message based on the user's role, or fallback to a default message
  const titleMessage = titleMessages[user.role] || 'Welcome to the Platform';

  return (
    <div className="container">
        <div className="title title-1">{titleMessage}</div> {/* Conditional title message */}
        <div className="title title-2">Welcome {user?.name}</div>
        <div className="item item-3"><AccountSideBar /></div>
        <div className="item item-4"><Stepper /></div>
    </div>
  );
}

export default TabInfo;
