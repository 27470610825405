import React, { useState } from 'react';
import { addUser } from '../../../utils/auth.controller'; 
import './Login.css'; 
import { SignupRadio } from '../../Design/Radio/RadioData';

const SignUp = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [company, setCompany] = useState('');
    const [role, setRole] = useState('investor'); // Default selection
    const [errorMessage, setErrorMessage] = useState(''); 
    const [successMessage, setSuccessMessage] = useState(''); 

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const newUser = await addUser({
                email,
                password,
                firstName,
                lastName,
                company: company || null, // Store as null if empty
                role,
                isVerified: false, 
            });

            console.log('User added successfully:', newUser);
            setSuccessMessage('User added successfully!'); 
            setErrorMessage(''); 
            
            setTimeout(() => {
                window.location.href = '/verify-email'; 
            }, 10);

        } catch (error) {
            console.error('Error during sign-up:', error.message);
            setErrorMessage(error.message); 
            setSuccessMessage('');
        }
    };

    return (
        <div className="login-page">
            <div className="login-image"></div> 
            <div className="login-form-container">
                <div className="login-form">
                    <h2>Sign Up</h2>

                    {successMessage && <p className="success-message">{successMessage}</p>}
                    {errorMessage && <p className="error-message">{errorMessage}</p>}

                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <div className="input-wrapper">
                                <i className="fa fa-envelope"></i>
                                <input 
                                    type="email" 
                                    value={email} 
                                    onChange={(e) => setEmail(e.target.value)} 
                                    placeholder="Email"
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="input-wrapper">
                                <i className="fa fa-lock"></i>
                                <input 
                                    type="password" 
                                    value={password} 
                                    onChange={(e) => setPassword(e.target.value)} 
                                    placeholder="Password"
                                    required 
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="input-wrapper">
                                <i className="fa fa-user"></i>
                                <input 
                                    type="text" 
                                    value={firstName} 
                                    onChange={(e) => setFirstName(e.target.value)} 
                                    placeholder="First Name"
                                    required 
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="input-wrapper">
                                <i className="fa fa-user"></i>
                                <input 
                                    type="text" 
                                    value={lastName} 
                                    onChange={(e) => setLastName(e.target.value)} 
                                    placeholder="Last Name"
                                    required 
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="input-wrapper">
                                <i className="fa fa-building"></i>
                                <input 
                                    type="text" 
                                    value={company} 
                                    onChange={(e) => setCompany(e.target.value)} 
                                    placeholder="Company (Optional)"
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Select Your Role:</label>
                            <SignupRadio value={role} onChange={setRole} />
                        </div>
                        <button type="submit">Sign Up</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default SignUp;
