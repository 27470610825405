// UserInfo.js
import React from 'react';

const UserInfo = () => {
  return (
    <div>
      <h1>User Information</h1>
      <p>This is the User Info section.</p>
    </div>
  );
};

export default UserInfo;
