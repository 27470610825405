import React, { useState, useEffect } from 'react';
import './Profile.css';
import logoWhite from './logo-white.png';
import supabase from '../../../config/supabaseClient';
import { useAuth } from '../../../context/AuthContext';  
import { refreshJwtAndSetCookie } from '../../../utils/refreshJwtAndCookie';

function Profile() {


  const [signatureText, setSignatureText] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedSignature, setSelectedSignature] = useState('');
  const { user, setUser } = useAuth(); 
  const [currentDate, setCurrentDate] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    dob_or_year_of_organization: '',
    home_address: '',
    employer: '',
    nature_of_business: '',
    position: '',
    duties: '',
    business_address: '',
    business_phone_number: '',
    checkbox_1: false,
    checkbox_2: false,
    checkbox_3: false,
    checkbox_4: false,
    checkbox_5a: false,
    checkbox_5ai: false,
    checkbox_5aii: false,
    checkbox_5aiii: false,
    checkbox_5aiv: false,
    checkbox_5b: false,
    checkbox_5c: false,
    checkbox_5d: false,
    checkbox_5e: false
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [id]: checked
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Log form data to the console
    // console.log('Form data:', formData);
    // console.log('User context value:', user);
    // Insert the accreditation data
    const { data, error } = await supabase
        .from('accreditation')
        .insert([
            {
                user_id: user.id,
                name: formData.name,
                dob_or_year_of_organization: formData.dob_or_year_of_organization,
                home_address: formData.home_address,
                employer: formData.employer,
                nature_of_business: formData.nature_of_business,
                position: formData.position,
                duties: formData.duties,
                business_address: formData.business_address,
                business_phone_number: formData.business_phone_number,
                checkbox_1: formData.checkbox_1,
                checkbox_2: formData.checkbox_2,
                checkbox_3: formData.checkbox_3,
                checkbox_4: formData.checkbox_4,
                checkbox_5a: formData.checkbox_5a,
                checkbox_5ai: formData.checkbox_5ai,
                checkbox_5aii: formData.checkbox_5aii,
                checkbox_5aiii: formData.checkbox_5aiii,
                checkbox_5aiv: formData.checkbox_5aiv,
                checkbox_5b: formData.checkbox_5b,
                checkbox_5c: formData.checkbox_5c,
                checkbox_5d: formData.checkbox_5d,
                checkbox_5e: formData.checkbox_5e,
                signature: selectedSignature.text,
                font: selectedSignature.font,
                signed_at: new Date(),
            }
        ]);

    if (error) {
        console.error("Error inserting data:", error);
    } else {
        console.log("Data inserted successfully:", data);

        // Now, update the user's role to 'investor'
        const { error: errorUpdate } = await supabase
            .from('users')
            .update({ role: 'investor' })  // Assuming 'role' is the column in the 'users' table
            .eq('user_id', user.user_id);  // Assuming 'user_id' is the unique identifier

        if (errorUpdate) {
            console.error("Error updating user role:", errorUpdate);
        } else {
            console.log("User role updated successfully");

            // Fetch the updated user data
            const { data: updatedUser, error: fetchError } = await supabase
                .from('users')
                .select('user_id, email, role, name')
                .eq('user_id', user.user_id)
                .single(); // Fetch the updated user data

            if (fetchError) {
                console.error("Error fetching updated user data:", fetchError);
            } else {
                // Now, refresh the JWT token and set the new cookie
                await refreshJwtAndSetCookie(updatedUser, setUser);
                // console.log('User role updated in JWT and cookie:', updatedUser);
            }
        }
    }
};

  const handleSignatureChange = (e) => {
    setSignatureText(e.target.value);
  };

  const handleFontClick = (font) => {
    setSelectedSignature({ text: signatureText, font });
    setIsPopupOpen(false);
  };

  useEffect(() => {
    const date = new Date();
    setCurrentDate(date.toLocaleDateString());
  }, []);

  return (
    <div className="contract-container">
      <div className="contract-content">
        <div className="contract-header">
          <img src={logoWhite} alt="Company Logo" className="company-logo" />
          <div className="company-name">
            <h1>Propertunity LLC</h1>
          </div>
        </div>
        <h4>ACCREDITATION</h4>
        <p>1. <strong>Introduction</strong></p>
        <p>The information elicited by this Accredited Investor Questionnaire (this “Questionnaire”) will be used to enable Propertunity Ltd (the “Company”) to determine whether you or the prospective subscriber on whose behalf you are providing this information, as the case may be, meets the suitability requirements for purchasers under Regulation D promulgated under the Securities Act of 1933, as amended (the “Securities Act”), and similar requirements of other applicable securities laws.</p>
  
        <p>2. <strong>Instructions</strong></p>
        <p>Your answers will, at all times, be kept strictly confidential; however, you agree that the Company may present this Questionnaire to such persons as it deems appropriate in order to ensure that the offer and sale of the Securities to you will not result in violation of the exemption from registration under the Securities Act and the securities laws of certain states.</p>
  
        <p>3. <strong>Personal Information</strong></p>
        <form onSubmit={handleFormSubmit}>
          <div className="personal-info">
            <div className="form-group">
              <label>Name:</label>
              <input className="input-line" type="text" value={formData.name} onChange={handleInputChange} name="name" required />
            </div>
            <div className="form-group">
              <label>Date of birth or year of organization:</label>
              <input className="input-line" type="text" value={formData.dob_or_year_of_organization} onChange={handleInputChange} name="dob_or_year_of_organization" required />
            </div>
            <div className="form-group">
              <label>Home address or, if other than an individual, principal office address:</label>
              <input className="input-line" type="text" value={formData.home_address} onChange={handleInputChange} name="home_address" required />
            </div>
          </div>
  
          <p>Employer Information (For Individual Investors)</p>
          <div className="employer-info">
            <div className="form-group">
              <label>Employer:</label>
              <input className="input-line" type="text" value={formData.employer} onChange={handleInputChange} name="employer" required />
            </div>
            <div className="form-group">
              <label>Nature of business:</label>
              <input className="input-line" type="text" value={formData.nature_of_business} onChange={handleInputChange} name="nature_of_business" required />
            </div>
            <div className="form-group">
              <label>Position:</label>
              <input className="input-line" type="text" value={formData.position} onChange={handleInputChange} name="position" required />
            </div>
            <div className="form-group">
              <label>Nature of duties:</label>
              <input className="input-line" type="text" value={formData.duties} onChange={handleInputChange} name="duties" required />
            </div>
            <div className="form-group">
              <label>Business address:</label>
              <input className="input-line" type="text" value={formData.business_address} onChange={handleInputChange} name="business_address" required />
            </div>
            <div className="form-group">
              <label>Business telephone number:</label>
              <input className="input-line" type="text" value={formData.business_phone_number} onChange={handleInputChange} name="business_phone_number" required />
            </div>
          </div>
  
          <p>4. <strong>Accredited Investor Status (For Individuals)</strong></p>
          <div>
            <label>Check all that apply:</label>
            <div className="checkbox-container">
              <input type="checkbox" id="checkbox1" onChange={handleCheckboxChange} name="checkbox_1" />
              <label htmlFor="checkbox1">I am a natural person whose individual net worth, or joint net worth with my spouse, exceeds $1,000,000.</label>
            </div>
            <div className="checkbox-container">
              <input type="checkbox" id="checkbox2" onChange={handleCheckboxChange} name="checkbox_2" />
              <label htmlFor="checkbox2">I am a natural person who had individual income exceeding $200,000 in 2023 and 2024, and I have a reasonable expectation of reaching the same income level in 2025.</label>
            </div>
            <div className="checkbox-container">
              <input type="checkbox" id="checkbox3" onChange={handleCheckboxChange} name="checkbox_3" />
              <label htmlFor="checkbox3">I am a natural person who had joint income with my spouse exceeding $300,000 in 2023 and 2024, and I have a reasonable expectation of reaching the same income level in 2025.</label>
            </div>
            <div className="checkbox-container">
              <input type="checkbox" id="checkbox4" onChange={handleCheckboxChange} name="checkbox_4" />
              <label htmlFor="checkbox4">I am a director or executive officer of the Company.</label>
            </div>
          </div>
  
          <p>5. <strong>Accredited Investor Status (For Entities)</strong></p>
          <div>
            <p>In the case of any partnership, corporation, trust, or other entity investor, the undersigned certifies that (check one):</p>
  
            <div className="checkbox-container">
              <input type="checkbox" id="checkbox5a" onChange={handleCheckboxChange} name="checkbox_5a" />
              <label htmlFor="checkbox5a">Each equity owner of the investor is an “accredited investor” because:</label>
            </div>
  
            <div className="subsection">
              <div className="checkbox-container">
                <input type="checkbox" id="checkbox5ai" onChange={handleCheckboxChange} name="checkbox_5ai" />
                <label htmlFor="checkbox5ai">(i) The equity owner of the investor is a natural person who had an individual income (exclusive of any income attributable to his or her spouse) in excess of $200,000 in each of 2023 and 2024 and reasonably expects to have an individual income in excess of $200,000 in 2025.</label>
              </div>
  
              <div className="checkbox-container">
                <input type="checkbox" id="checkbox5aii" onChange={handleCheckboxChange} name="checkbox_5aii" />
                <label htmlFor="checkbox5aii">(ii) The equity owner is a natural person who has an “individual net worth” (or who, with his or her spouse, has a combined individual net worth) in excess of $1,000,000.</label>
              </div>
  
              <div className="checkbox-container">
                <input type="checkbox" id="checkbox5aiii" onChange={handleCheckboxChange} name="checkbox_5aiii" />
                <label htmlFor="checkbox5aiii">(iii) The equity owner is a director or executive officer of the Company.</label>
              </div>
  
              <div className="checkbox-container">
                <input type="checkbox" id="checkbox5aiv" onChange={handleCheckboxChange} name="checkbox_5aiv" />
                <label htmlFor="checkbox5aiv">(iv) The equity owner is either a bank, insurance company, investment company, business development company, or other financial institution.</label>
              </div>
            </div>
  
            <div className="checkbox-container">
              <input type="checkbox" id="checkbox5b" onChange={handleCheckboxChange} name="checkbox_5b" />
              <label htmlFor="checkbox5b">(b) The investor is a private business development company as defined in Section 202(a)(22) of the Investment Advisers Act of 1940.</label>
            </div>
  
            <div className="checkbox-container">
              <input type="checkbox" id="checkbox5c" onChange={handleCheckboxChange} name="checkbox_5c" />
              <label htmlFor="checkbox5c">(c) The investor is an organization described in Section 501(c)(3) of the Internal Revenue Code of 1986, as amended, not formed for the specific purpose of the contemplated investment, with total assets exceeding $5,000,000.</label>
            </div>
  
            <div className="checkbox-container">
              <input type="checkbox" id="checkbox5d" onChange={handleCheckboxChange} name="checkbox_5d" />
              <label htmlFor="checkbox5d">(d) The investor is a corporation, Massachusetts or similar business trust, partnership, or limited liability company not formed for the specific purpose of the contemplated investment, with total assets exceeding $5,000,000.</label>
            </div>
  
            <div className="checkbox-container">
              <input type="checkbox" id="checkbox5e" onChange={handleCheckboxChange} name="checkbox_5e" />
              <label htmlFor="checkbox5e">(e) The investor is a trust, not formed for the specific purpose of the contemplated investment, with total assets exceeding $5,000,000 and whose purchase is directed by a “sophisticated person,” as defined in Rule 506(b)(2)(ii) of Regulation D.</label>
            </div>
          </div>
  
          <p>6. <strong>Certification</strong></p>
          <p>The undersigned certifies that the foregoing responses are true, complete, and accurate to the best of the undersigned’s knowledge and belief. The undersigned will provide such further information as may be requested by the Company to verify this response. The undersigned will notify the Company in writing regarding any material change to this response prior to the closing of the purchase of securities from the Company. Absent such notification, the issuance of securities in the name of the undersigned shall be deemed to be an automatic affirmation by the undersigned of the truth and accuracy of the statements and information set forth above.</p>
  
          {/* Signature Section */}
          <div className="signature-section">
            <div className="signature-line-container">
              <label>Signature:</label>
              <div
                className="line clickable"
                onClick={() => setIsPopupOpen(true)}
              >
                {selectedSignature.text ? (
                  <div 
                    className="selected-signature" 
                    style={{ fontFamily: selectedSignature.font }}
                  >
                    {selectedSignature.text}
                  </div>
                ) : (
                  <span>Click to Sign</span>
                )}
              </div>
            </div>
  
            <div className="date-line-container">
              <label>Date:</label>
              <div className="line"></div>
              {currentDate && (
                <div className="selected-date">{currentDate}</div>
              )}
            </div>
          </div>
  
          {isPopupOpen && (
            <div className="signature-popup-overlay">
              <div className="signature-popup">
                <h4>Enter Your Name for Signature:</h4>
                <input
                  type="text"
                  value={signatureText}
                  onChange={handleSignatureChange}
                  placeholder="Type your name"
                />
                {signatureText && <h4>Select your signature style:</h4>}
                <div className="signature-preview">
                  <div
                    className="signature-display"
                    style={{ fontFamily: 'Great Vibes' }}
                    onClick={() => handleFontClick('Great Vibes')}
                  >
                    {signatureText}
                  </div>
                  <div
                    className="signature-display"
                    style={{ fontFamily: 'Pacifico' }}
                    onClick={() => handleFontClick('Pacifico')}
                  >
                    {signatureText}
                  </div>
                  <div
                    className="signature-display"
                    style={{ fontFamily: 'Dancing Script' }}
                    onClick={() => handleFontClick('Dancing Script')}
                  >
                    {signatureText}
                  </div>
                </div>
              </div>
            </div>
          )}
  
          <button type="submit" className="submit-button">
            Save
          </button>
        </form>
      </div>
    </div>
  );
  
}

export default Profile;
