import React, { useState, useEffect, useRef } from 'react';
import { logoBase64 } from './logo';
import './Header.css';
import { logout } from '../../../utils/auth.controller';
import { useAuth } from '../../../context/AuthContext';

function Header() {
  const { user } = useAuth();
  const [showSubLinks, setShowSubLinks] = useState(null); // Tracks the last clicked section
  const navRef = useRef(); // Reference for detecting clicks outside

  // Toggle dropdown visibility
  const handleToggleSubLinks = (section) => {
    setShowSubLinks((prevState) => (prevState === section ? null : section)); // Close if clicked again, else open the new one
  };

  // Handle click outside to close the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setShowSubLinks(null); // Close all dropdowns if clicked outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside); // Cleanup event listener
  }, []);

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      await logout();
      window.location.href = '/login';
    } catch (error) {
      console.error("Error during logout:", error);
      alert("An error occurred while logging out.");
    }
  };

  return (
    <nav className="header" ref={navRef}>
      <div className="logo-container">
        <a href="/" className="logo-link">
          <img src={logoBase64} alt="Propertunity Logo" className="logo" />
          <span className="company-name">Propertunity</span>
        </a>
      </div>
      
      <div className="nav-links">
        {/* Our Firm Section - Dropdown */}
        <div className="nav-item">
          <button className="nav-link" onClick={() => handleToggleSubLinks('section1')}>
            Our Firm
            <span className={`dropdown-icon ${showSubLinks === 'section1' ? 'rotated' : ''}`}></span>
          </button>
          {showSubLinks === 'section1' && (
            <div className="sub-links">
              <a href="https://propertunityltd.com/about-us" target="_blank" rel="noopener noreferrer">About Propertunity</a>
              <a href="https://propertunityltd.com/our-people" target="_blank" rel="noopener noreferrer">Our People</a>
              <a href="https://propertunityltd.com/contact-us" target="_blank" rel="noopener noreferrer">Contact</a>
            </div>
          )}
        </div>

        {/* Our Business Section - Dropdown */}
        <div className="nav-item">
          <button className="nav-link" onClick={() => handleToggleSubLinks('section2')}>
            Our Business
            <span className={`dropdown-icon ${showSubLinks === 'section2' ? 'rotated' : ''}`}></span>
          </button>
          {showSubLinks === 'section2' && (
            <div className="sub-links">
              <a href="https://propertunityltd.com/equity" target="_blank" rel="noopener noreferrer">Equity</a>
              <a href="https://propertunityltd.com/bonds" target="_blank" rel="noopener noreferrer">Fixed Income</a>
              <a href="https://propertunityltd.com/investments" target="_blank" rel="noopener noreferrer">Investments</a>
            </div>
          )}
        </div>

        {/* Our Impact Section - Dropdown */}
        <div className="nav-item">
          <button className="nav-link" onClick={() => handleToggleSubLinks('section4')}>
            Our Impact
            <span className={`dropdown-icon ${showSubLinks === 'section4' ? 'rotated' : ''}`}></span>
          </button>
          {showSubLinks === 'section4' && (
            <div className="sub-links">
              <a href="https://propertunityltd.com/community" target="_blank" rel="noopener noreferrer">Community</a>
              <a href="https://propertunityltd.com/owners" target="_blank" rel="noopener noreferrer">Owners</a>
            </div>
          )}
        </div>

        {/* Conditional Login/Logout Section */}
        <div className="nav-item">
          {user ? (
            <button onClick={handleLogout} className="nav-link">Logout</button>
          ) : (
            <a href="https://portal.propertunityltd.com/login" target="_blank" rel="noopener noreferrer" className="nav-link">Login</a>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Header;
