import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const rolePermissions = {
  admin: ['admin', 'investor', 'fundraiser', 'user'],  // Admin can access all
  investor: ['investor', 'user'],  // Investor can access Portal + Account
  fundraiser: ['fundraiser', 'user'],  // Sponsor can access Sponsor + Account
  user: ['user']  // Regular user can only access Account
};

const ProtectedRoute = ({ Component, requiredRole }) => {
  const { user, isAuthenticated, loading } = useAuth();

  console.log('Protected Route - Auth state:', { 
    user, 
    isAuthenticated, 
    loading,
    requiredRole,
    userRole: user?.role,
    hasAccess: user ? rolePermissions[user.role]?.includes(requiredRole) : false
  });

  if (loading) {
    return <div>Loading...</div>;  // Show loading until auth check finishes
  }

  if (!isAuthenticated || !user) {
    return <Navigate to="/login" replace />;  // Redirect if not logged in
  }

  // Check if user's role permissions include the required role
  if (!rolePermissions[user.role]?.includes(requiredRole)) {
    return <Navigate to="/no-access" replace />;  // Redirect if not authorized
  }

  return <Component />;
};

export default ProtectedRoute;
