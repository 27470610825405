import { SignJWT, jwtVerify } from 'jose';

export const generateTokenAndSetCookie = async (user) => {
    try {
        // Extensive logging of user data
        console.log('Full User Object:', JSON.stringify(user, null, 2));
        console.log('user.user_id:', user.user_id);
        console.log('user.email:', user.email);
        console.log('user.role:', user.role);
        console.log('user.fname:', user.fname);
        console.log('user.lname:', user.lname);


        // Create a JWT token with the user's id, email, and role
        const token = await new SignJWT({
            user_id: user.user_id,
            email: user.email,
            role: user.role,
            fname: user.fname,
            lname: user.lname,
        })
        .setExpirationTime('30d')
        .setProtectedHeader({ alg: 'HS256' }) 
        .sign(new TextEncoder().encode('451735'));

        // Decode and log the token contents immediately
        try {
            const { payload } = await jwtVerify(
                token, 
                new TextEncoder().encode('451735')
            );
            console.log('Decoded Token Payload:', JSON.stringify(payload, null, 2));
        } catch (decodeError) {
            console.error('Token Decoding Error:', decodeError);
        }

        // Cookie settings for HttpOnly, secure, and expiration
        const maxAge = 30 * 24 * 60 * 60 * 1000; // 30 days
        const cookieOptions = `SameSite=Strict; Max-Age=${maxAge}; Path=/; Secure`;  

        // Set the cookie in the browser (JWT in cookie)
        document.cookie = `token=${token}; ${cookieOptions}`;

        console.log('Token generated and cookie set:', token);
        return token; 
    } catch (error) {
        console.error('Error generating token or setting cookie:', error);
        console.error('Error details:', error.stack);
        throw new Error('Failed to generate token or set cookie');
    }
};