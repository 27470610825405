import React, { useState } from 'react';
import StripeCheckout from './StripeCheckout';
import './CheckoutPage.css'; 

const CheckoutPage = () => {
  const [amount, setAmount] = useState(100000); 
  const [quantity, setQuantity] = useState(1);
  const [showCheckout, setShowCheckout] = useState(false);
  
  const handleProceedToCheckout = () => {
    setShowCheckout(true);
  };

  return (
    <div className="checkout-page">
      <h1 style={{ fontSize: '18px' }}>Invest</h1>

      
      {!showCheckout ? (
        <div className="checkout-summary">
          <div className="product-details">
            <h2 style={{ fontSize: '16px' }}>Your Order</h2>
            <div className="product-item">
              <span style={{ fontSize: '14px' }}>Contract </span>
              <span>${(amount / 100).toFixed(2)}</span>
            </div>
            <div className="quantity-controls">
              <label style={{ fontSize: '14px' }} htmlFor="quantity">Quantity:</label>
              <input
                type="number"
                id="quantityInput"
                min="1"
                step="1"
                value={quantity}
                onChange={(e) => setQuantity(parseInt(e.target.value, 10) || 1)}
                className="custom-quantity-input"
              />

            </div>
            <div className="total">
              <strong style={{ fontSize: '14px' }} >Total:</strong>
              <strong style={{ fontSize: '14px' }} >${((amount * quantity) / 100).toFixed(2)}</strong>
            </div>
          </div>
          
          <div className="amount-controls">
            <label style={{ fontSize: '14px' }}  htmlFor="amount">Amount ($):</label>
            <input
              type="number"
              id="amount"
              min="1000"
              step="1000"
              value={(amount / 100).toFixed(2)}
              onChange={(e) => setAmount(Math.round(parseFloat(e.target.value) * 100))}
              className="custom-quantity-input"
            />
          </div>
          
          <button className="checkout-button" onClick={handleProceedToCheckout} >
            Pay
          </button>
        </div>
      ) : (
        <StripeCheckout 
          amount={amount * quantity} 
          currency="usd" 
          description="T-shirt purchase" 
        />
      )}
    </div>
  );
};

export default CheckoutPage;
