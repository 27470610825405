import React, { useState } from 'react';
import { useAuth } from '../../../context/AuthContext'; 
import './Stepper.css'; 

const Stepper = () => {
  const { user } = useAuth();  // Access the user object from context
  const [showBubble, setShowBubble] = useState(false); // To control bubble visibility
  const [bubblePosition, setBubblePosition] = useState({ left: 0, top: 0 }); // For positioning the bubble
  const [bubbleMessage, setBubbleMessage] = useState(''); // For setting the bubble message

  // Array for step names
  const steps = ['Registered', 'Accredited', 'Funded', 'Invested'];

  // Handle step click to show a comic bubble and message
  const handleStepClick = (index) => {
    setBubbleMessage(`Step ${index + 1} clicked`);
    const stepElement = document.getElementById(`step-${index}`);
    const stepRect = stepElement.getBoundingClientRect(); // Get position of the step

    setBubblePosition({
      left: stepRect.left + stepRect.width / 2 - 50, // Position the bubble above the center of the step
      top: stepRect.top - 50 // Position the bubble above the step
    });

    setShowBubble(true);
  };

  // Function to determine if the step should be orange based on user role
  const getStepClass = (index) => {
    if (!user) {
      return ''; // Return default if user is not loaded
    }

    const role = user.role;  // Get role from the user object
    console.log(`Role: ${role}, Step Index: ${index}`);  // Debugging: log role and index

    if (role === 'user' && index === 0) {
      console.log(`Step ${index} should be orange for user`);
      return 'orange'; // For user, only the first circle is orange
    } else if (role === 'investor' && index <= 1) {
      console.log(`Step ${index} should be orange for investor`);
      return 'orange'; // For investor, first two circles are orange
    }
    console.log(`Step ${index} is default (no color)`);
    return ''; // Default (no color change)
  };

  return (
    <div className="stepper">
      <h4>Your Progress</h4>
      {steps.map((step, index) => (
        <div
          key={index}
          id={`step-${index}`}
          className={`step ${getStepClass(index)}`} // Only apply getStepClass for color
          onClick={() => handleStepClick(index)} // Trigger the comic bubble on click
        >
          <div className="step-icon">{index + 1}</div>
          <div className="step-label">{step}</div>
        </div>
      ))}

      {/* Comic bubble that will show on step click */}
      {showBubble && (
        <div
          className="bubble"
          style={{
            left: `${bubblePosition.left}px`,
            top: `${bubblePosition.top}px`,
          }}
        >
          <div className="bubble-arrow"></div>
          <div className="bubble-message">{bubbleMessage}</div>
        </div>
      )}
    </div>
  );
};

export default Stepper;
