import React from 'react';
import './House.css';

const HouseCard = ({ house }) => {
  // Helper function to format the opening bid and assessed value as currency
  const formatCurrency = (value) => {
    const numericValue = typeof value === 'string' 
      ? parseFloat(value.replace('$', '').replace(',', '')) 
      : value;

    const numberFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      notation: 'compact',
      compactDisplay: 'short',
    });

    return numberFormatter.format(numericValue);
  };

  // Helper function to format the date
  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = new Date(date).toLocaleDateString('en-US', options);
    return formattedDate;
  };

  // // Helper function to format the time
  // const formatTime = (time) => {
  //   const date = new Date(`1970-01-01T${time}Z`);
  //   return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  // };

  // Function to manually break up the address into components
  const parseAddress = (address) => {
    // Split by commas to separate main components
    const addressParts = address.split(',');

    // List of valid street types
    const streetTypes = [
      'ST', 'RD', 'DR', 'AVE', 'AVEN', 'LN', 'LANE', 'CT', 'CIR', 'WAY', 'PL', 'BLVD', 'BOUL', 'TRL', 'TER', 'HWY', 'PKWY', 'LOOP',
      'CV', 'PIKE', 'RUN', 'RDG', 'PT', 'PARK', 'CRK', 'SQ', 'TRCE', 'PATH', 'EXT', 'XING', 'PASS', 'HL', 'HILL', 'TPKE', 'BND', 'HTS',
      'BR', 'WALK', 'VW', 'VIEW', 'HOLW', 'ROW', 'LK', 'PLZ', 'FRK', 'GLN', 'ALY', 'LNDG', 'VLG', 'CRES', 'EST', 'VIS', 'RIV', 'MNR', 
      'IS', 'GRV', 'FWY', 'HLS', 'VLY', 'GRN', 'CMN', 'BLF', 'MDWS', 'SPGS', 'ESTS', 'SPUR', 'CRST', 'EXPY', 'CYN', 'MTN', 'BYP', 'OVAL', 
      'FRST', 'CTR', 'LAND', 'CURV', 'GDNS', 'MDW', 'COR', 'FLS', 'CMNS', 'KNL', 'STA', 'SHRS', 'HBR', 'BRK', 'RNCH', 'BCH', 'ISLE', 'CLB', 
      'RTE', 'ML', 'MEWS', 'PNES', 'SHR', 'PR', 'HVN', 'PSGE', 'SPG', 'CSWY', 'CRSE', 'KY', 'MALL', 'BRG', 'KNLS', 'MT', 'CP', 'FLD', 'STS', 
      'PNE', 'RDS', 'RST', 'LDG', 'SMT', 'LKS', 'TRAK', 'GTWY', 'GDN', 'FLT', 'JCT', 'WL', 'BYU', 'CLF', 'DV', 'FRY', 'FRD', 'VIA', 'FLDS',
      'DL', 'XRD', 'ARC', 'STRA', 'STRM', 'WLS', 'CTS', 'ORCH', 'TRFY', 'MLS', 'NCK', 'BLFS', 'CORS', 'FRG', 'PLNS', 'BTM', 'TRWY', 'PLN',
      'DM', 'DAM', 'ANX', 'RUE', 'UN', 'VL', 'EXTS', 'CTRS', 'PORT', 'PRT', 'VLGS', 'NULL', 'CLFS', 'FT', 'FLTS', 'SHLS', 'INLT', 'SKWY',
      'LGT', 'FALL', 'RPDS', 'RADL', 'GRNS', 'SHL', 'RDGS', 'OPAS', 'GRVS', 'CPE', 'LCK', 'FRKS', 'BRKS', 'ROAD', 'WALL', 'LGTS', 'RAMP', 
      'LF', 'MSN', 'CVS', 'TUNL', 'MTWY', 'DRS', 'PTS', 'VWS', 'KYS', 'ISS', 'MTNS', 'XRDS', 'TRLR', 'WAYS', 'UPAS', 'CIRS', 'BG', 'BURG', 
      'LCKS', 'SQS'
    ];

    // Valid directions (N, S, E, W, NE, NW, SE, SW)
    const validDirections = ['N', 'S', 'E', 'W', 'NE', 'NW', 'SE', 'SW'];

    // First, split by spaces to separate individual components of the address
    const streetParts = addressParts[0].trim().split(' '); // First part is the street address

    // Extract street number (usually the first part)
    const streetNumber = streetParts[0] || ''; 

    // Extract pre-direction (e.g., N, S, E, W, or NE, NW, etc.)
    const preDirection = streetParts.length > 1 && validDirections.includes(streetParts[1].toUpperCase()) ? streetParts[1] : ''; 

    // Street name (everything between number and street type)
    let streetName = streetParts.slice(preDirection ? 2 : 1).join(' ') || '';
    
    // Find if a street type exists at the end of the street name
    let streetType = '';
    for (let i = streetParts.length - 1; i >= 0; i--) {
      if (streetTypes.includes(streetParts[i].toUpperCase())) {
        streetType = streetParts[i];
        streetName = streetParts.slice(preDirection ? 2 : 1, i).join(' ') || '';
        break;
      }
    }

    // Remainder of the address after the main street address and type
    const other = addressParts.slice(1).join(', ').trim() || '';

    return {
      streetNumber,
      preDirection,
      streetName,
      streetType,
      other,
    };
  };

  // Parse the property address into components
  const addressComponents = parseAddress(house.propertyAddress);

  return (
    <div className="house-card">
      <h4>{addressComponents.streetNumber} {addressComponents.preDirection} {addressComponents.streetName} {addressComponents.streetType}</h4>

      {/* <div className="address-details">
         <p><strong>Street Number:</strong> {addressComponents.streetNumber}</p>
        <p><strong>Pre Direction:</strong> {addressComponents.preDirection}</p>
        <p><strong>Street Name:</strong> {addressComponents.streetName}</p>
        <p><strong>Street Type:</strong> {addressComponents.streetType}</p>
        <p><strong>Other Info:</strong> {addressComponents.other}</p>
        <p><strong>County:</strong> {house.county}</p>
      </div> */}

      <p><strong>Date:</strong> {formatDate(house.date)}</p> 
      {/* <p><strong>Time:</strong> {formatTime(house.time)}</p>  */}
      <p><strong>Opening Bid:</strong> {formatCurrency(house.openingBid)}</p> 
      <p><strong>Assessed Value:</strong> {formatCurrency(house.assessedValue)}</p> 
    </div>
  );
};

export default HouseCard;
