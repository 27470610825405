import { FaUser, FaRegIdCard, FaCertificate } from 'react-icons/fa'; // Import the icons

export const AccountSideBarData = [
  {
    title: 'User Info',
    icon: <FaUser />, 
    component: 'UserInfo', 
  },
  {
    title: 'Contact Info',
    icon: <FaRegIdCard />,
    component: 'ContactInfo',
  },
  {
    title: 'Accreditation',
    icon: <FaCertificate />,
    component: 'Accreditation',
  },
];
