import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Functionality/login/Login';
import Portal from './pages/investor/Portal';
import Header from './components/Functionality/Header/Header';
import Footer from './components/Functionality/Footer/Footer';
import './App.css';
import SignUp from './components/Functionality/login/Signup';
import VerifyEmail from './components/Functionality/login/VerifyEmail';
import PasswordReset from './components/Functionality/login/PasswordReset';
import { AuthProvider, useAuth } from './context/AuthContext';
import ForgotPassword from './components/Functionality/login/ForgotPassword';
import Account from './pages/user/Account';
import NoAccess from './pages/NoAccess';
import Dashboard from './pages/admin/Admin';
import Sponsor from './pages/sponsor/Sponsor';
import ProtectedRoute from './utils/protectedRoutes';

// Define the App component
const App = () => {
  const { user, loading } = useAuth();

  useEffect(() => {
    console.log('App.js - user:', user);
  }, [user]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="App">
        <div className="Header"><Header /></div>  
        <div className="App-main">
          <Routes>
            {/* Unprotected Routes */}
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/reset-password/:token" element={<PasswordReset />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />

            {/* Protected Routes */}
            <Route
              path="/account"
              element={
                <ProtectedRoute
                  Component={Account}  // User page
                  requiredRole="user"  // User and above can access
                />
              }
            />
            <Route
              path="/portal"
              element={
                <ProtectedRoute
                  Component={Portal}  // Investor page
                  requiredRole="investor"  // Investor and above can access
                />
              }
            />
            <Route
              path="/admin"
              element={
                <ProtectedRoute
                  Component={Dashboard}  // Admin page
                  requiredRole="admin"  // Only admin can access
                />
              }
            />
            <Route
              path="/sponsor"
              element={
                <ProtectedRoute
                  Component={Sponsor}  
                  requiredRole="fundraiser" 
                />
              }
            />

            {/* No Access Page for Unauthorized Users */}
            <Route path="/no-access" element={<NoAccess />} />
          </Routes> 
        </div> 
        <div className="Footer"><Footer /></div>
      </div>
    </Router>
  );
};

// Export the component wrapped in AuthProvider
const WrappedApp = () => (
  <AuthProvider>
    <App />
  </AuthProvider>
);

export default WrappedApp;
