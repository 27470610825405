import React from 'react';
import { useAuth } from '../../../context/AuthContext';
import Profile from '../Profile/Profile';
import './Accreditation.css'; // Import the CSS file

function Accreditation() {
  const { user } = useAuth(); // Access user from AuthContext
  
  // Check if user is an investor
  const isInvestor = user?.role === 'investor';

  return (
    <div className="accreditation-container">
      {isInvestor ? (
        <>
          <h3>Self-Accreditation</h3>
          <p>You are accredited!</p>
        </>
      ) : (
        <>
          <h3>Self-Accreditation</h3>
          <p>Please complete your investor profile to unlock the investment view.</p>
          <Profile /> {/* Show Profile component if not accredited */}
        </>
      )}
    </div>
  );
}

export default Accreditation;