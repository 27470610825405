
export const formatCompactedValue = (value) => {
    const options = {
      notation: "compact",
      compactDisplay: "short",
    };
    
    const formatter = new Intl.NumberFormat("en-US", options);
    return formatter.format(value);
  };
  