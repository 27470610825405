import React, { useState } from 'react';
import './CardDetail.css';
import CheckoutButton from '../../../../Design/Button/Checkout/Checkout';
import CheckoutPage from '../../../../Functionality/Stripe/CheckoutPage';

const CardDetail = ({ investment }) => { 
  // ✅ Always call hooks at the top level
  const [showCheckout, setShowCheckout] = useState(false);

  // ✅ Handle missing investment after hooks
  if (!investment) {
    return <div>Investment not found.</div>; 
  }

  // const squarePaymentLink = 'https://square.link/u/V5M1BcoF';

  return (
    <div className="investment-detail-container">
      <div className="investment-content">
        {/* Buy Box Section */}
        <div className="buy-box">
          <h3>EARLY BIRD TERMS</h3>
          <p><strong>{investment.amountRaised} LEFT</strong></p>
          <p>{investment.raisedAmount} raised from {investment.investorsCount} investors</p>
          
          <div className="investment-progress">
            <p><strong>INVEST</strong></p>
            <p>$250 Minimum</p>
            
            {/* Checkout Button */}
            <CheckoutButton onClick={() => setShowCheckout(!showCheckout)} />
          </div>

          {/* Investor Terms */}
          <div className="terms-section">
            <div className="terms-header">
              <strong>Investor Terms</strong>
            </div>
            <div className="terms-body">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam et arcu quis risus scelerisque sollicitudin. Suspendisse viverra nulla et magna tempor, eu consectetur arcu efficitur.</p>
            </div>
          </div>
        </div>

        <hr className="divider" />
        
        {/* Investment Details */}
        <div>
          <h2>{investment.title}</h2>
          <img src={investment.image} alt={investment.title} className="investment-image" />
          <p>{investment.description}</p>
          <p>Tagline: {investment.tagline}</p>
          <p>Amount Raised: {investment.amountRaised}</p>
          <p>Valuation Cap: {investment.valuationCap}</p>
        </div>

        {/* Checkout Page (Shows when button is clicked) */}
        {showCheckout && <CheckoutPage />}
      </div>
    </div>
  );
};

export default CardDetail;
