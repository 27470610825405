import React from 'react';
import cardData from './CardData'; // Import card data
import './Card.css';

const Card = ({ onCardClick }) => {
  return (
    <div className="card-container">
      {cardData.map((card) => (
        <div
          key={card.id}
          className="card-link"
          onClick={() => onCardClick(card)} // Pass the correct card object to the onCardClick function
        >
          <div className="card">
            {/* Image at the top of the card */}
            <div className="card-image">
              <img src={card.image} alt={card.title} />
            </div>

            {/* Circle with profile picture in the middle */}
            <div className="card-profile">
              {card.profilePic}
            </div>

            {/* Bottom half with text */}
            <div className="card-info">
              <h3>{card.name}</h3>
              <div className="tagline">{card.tagline}</div> {/* Display tagline */}
              <p>{card.description}</p>

              {/* Tags display */}
              <div className="tags">
                {card.tags.map((tag, index) => (
                  <span key={index} className="tag">{tag}</span>
                ))}
              </div>

              <div className="financials">
                <p>AMOUNT RAISED: {card.amountRaised}</p>
                <p>VALUATION CAP: {card.valuationCap}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Card;
