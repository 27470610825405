import React, { useState } from 'react';
import RadioGroup from './RadioGroup';

function RadioData() {
    const items = [
        { value: 'convertible', label: 'Convertible Note' },
        { value: 'revenue-share', label: 'Revenue Share' },
        { value: 'safe-equity', label: 'SAFE Equity' }
    ];

    const [value, setValue] = useState(null);

    return (
        <RadioGroup
            name="investment"
            items={items}
            value={value}
            onChange={(value) => {
                setValue(value);
                console.log(value);
            }}
        />
    );
}

function SignupRadio({ value, onChange }) {
    const items = [
        { value: 'investor', label: 'Looking to Invest' },
        { value: 'fundraiser', label: 'Looking to Raise Funds' }
    ];

    return (
        <RadioGroup
            name="role"
            items={items}
            value={value}
            onChange={onChange}
        />
    );
}

export { RadioData, SignupRadio };
