import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';

// Use environment variable for the publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST);

// The checkout form component that will be wrapped by Elements
const CheckoutForm = ({ clientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [succeeded, setSucceeded] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setProcessing(true);
    setError(null);

    // Confirm the payment
    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/payment-success`,
      },
      redirect: 'if_required',
    });

    if (result.error) {
      setError(result.error.message);
      setProcessing(false);
    } else if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
      setSucceeded(true);
      setProcessing(false);
      setPaymentMethod(result.paymentIntent.payment_method_types[0]);
    } else {
      setError('An unexpected error occurred.');
      setProcessing(false);
    }
  };

  const paymentElementOptions = {
    layout: 'tabs',
    defaultValues: {
      billingDetails: {
        name: 'Jane Doe',
        email: 'janedoe@example.com',
        phone: '888-888-8888',
        address: {
          line1: '123 Main St',
          city: 'San Francisco',
          state: 'CA',
          postal_code: '94111',
          country: 'US',
        },
      },
    },
  };

  return (
    <form onSubmit={handleSubmit} className="payment-form">
      <div className="form-group">
        <PaymentElement options={paymentElementOptions} />
      </div>
      
      {error && (
        <div className="error-message">
          {error}
        </div>
      )}
      
      {succeeded ? (
        <div className="success-message">
          Payment successful! Method: {paymentMethod}
        </div>
      ) : (
        <button 
          type="submit" 
          disabled={!stripe || processing} 
          className="payment-button"
        >
          {processing ? 'Processing...' : 'Pay Now'}
        </button>
      )}
    </form>
  );
};

// The main checkout component that creates the payment intent and renders the form
const StripeCheckout = ({ amount = 2000, currency = 'usd', description = 'Product purchase' }) => {
  const [clientSecret, setClientSecret] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Create a payment intent when the component mounts
    const createPaymentIntent = async () => {
      try {
        setLoading(true);
        const response = await fetch('https://portal.propertunityltd.com/api/stripe/create-payment-intent', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            amount,
            currency,
            description,
            payment_method_types: ['card', 'us_bank_account'], // Enable both card and ACH
          }),
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Failed to create payment intent: ${errorText}`);
        }

        const data = await response.json();
        setClientSecret(data.clientSecret);
      } catch (err) {
        setError(err.message);
        console.error('Error creating payment intent:', err);
      } finally {
        setLoading(false);
      }
    };

    createPaymentIntent();
  }, [amount, currency, description]);

  const options = {
    clientSecret,
    appearance: {
      theme: 'stripe',
      variables: {
        colorPrimary: '#001E2D',
        colorBackground: '#ffffff',
        colorText: '#001E2D',
        colorDanger: '#df1b41',
        fontFamily: 'Arial, sans-serif',
        spacingUnit: '4px',
        borderRadius: '4px',
        fontSizeBase: '12px'
      },
    },
  };

  if (loading) {
    return <div className="loading">Loading payment form...</div>;
  }

  if (error) {
    return <div className="error-container">Error: {error}</div>;
  }

  return (
    <div className="stripe-checkout-container">
      <h2>Complete Your Payment</h2>
      <p className="checkout-description">Amount: ${(amount / 100).toFixed(2)} {currency.toUpperCase()}</p>
      
      {clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm clientSecret={clientSecret} />
        </Elements>
      )}
    </div>
  );
};

export default StripeCheckout;