import React from 'react';
import { TbCoinFilled, TbCoinBitcoinFilled } from 'react-icons/tb';
import styles from './Icon.module.css';

const Icon = ({ type, size, isActive }) => {
  // Set color based on whether the icon is active or not
  const iconColor = isActive ? '#001E2D' : '#D3D3D3'; // Active color vs. inactive color (light gray)
  
  // Set border color and size
  const iconBorder = `3px solid ${iconColor}`;  // Applying 3px border with dynamic color

  // Conditionally render the appropriate icon based on the `type`
  const renderIcon = () => {
    switch (type) {
      case 'USD':
        return <TbCoinFilled className={styles.icon} style={{ width: size, height: size, color: iconColor, border: iconBorder }} />;
      case 'Crypto':
        return <TbCoinBitcoinFilled className={styles.icon} style={{ width: size, height: size, color: iconColor, border: iconBorder }} />;
      default:
        return null;
    }
  };

  return <div className={styles['icon-container']}>{renderIcon()}</div>;
};

export default Icon;
