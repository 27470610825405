// import styles from  './Account.module.css';
import { Tabs, Tab, TabList, TabPanel } from '../../components/TabsAll/Tabs/Tabs';
import TabInfo from '../../components/TabsAll/Account/TabInfo/TabInfo';
import Reports from '../../components/TabsAll/Account/Reports/Reports';


// import { Link } from 'react-router-dom'; 

function Account() {
  return (
    <div className="Account"> 
      <Tabs>
        <TabList>
          <Tab index={0}>Info</Tab>
          <Tab index={1}>Bank</Tab>
          <Tab index={2}>Reports</Tab>
          <Tab index={3}>Tax Center</Tab>
          <Tab index={4}>Messages</Tab>
          {/* <Tab index={5}><Link to="/portal" style={{ textDecoration: 'none', color: 'inherit' }}>Portal</Link></Tab> */}
        </TabList>

        <TabPanel index={0}><TabInfo /></TabPanel>
        <TabPanel index={1}>Content Banking</TabPanel>
        <TabPanel index={2}><Reports /></TabPanel>
        <TabPanel index={3}>Content documents</TabPanel>
        <TabPanel index={3}>Messages</TabPanel>
      </Tabs>
    </div>
  );
}

export default Account;
