import React, { useState, useEffect } from 'react';
import supabase from '../../../config/supabaseClient';
import HouseCard from './HouseCard';
import './House.css';
import Pagination from '../../Design/Pagination/Pagination';
import { BsFillHousesFill } from "react-icons/bs";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { formatCompactedValue } from '../../../utils/formats';

function HouseList({ countyFilter = "citrus", setCountyStats }) {
  const [fetchError, setFetchError] = useState(null);
  const [houses, setHouses] = useState([]);
  const [filteredHouses, setFilteredHouses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(3);

  const [statistics, setStatistics] = useState({ totalValue: 0, numProperties: 0 });

  useEffect(() => {
    const fetchHouses = async () => {
      try {
        const { data, error } = await supabase
          .from('auctions')
          .select('id, date, time, county, openingBid, propertyAddress, assessedValue');

        if (error) {
          setFetchError('Could not fetch the auctions');
          setHouses([]);
        }

        if (data) {
          const uniqueHouses = Array.from(
            new Map(data.map((house) => [house.id, house])).values()
          );

          setHouses(uniqueHouses);
          setFetchError(null);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchHouses();
  }, []);

  useEffect(() => {
    const filtered = houses.filter((house) => house.county === countyFilter);
    setFilteredHouses(filtered);

    const totalValue = filtered.reduce((sum, house) => {
      const value = isNaN(house.assessedValue) ? 0 : house.assessedValue;
      return sum + value;
    }, 0);

    setStatistics({
      totalValue: totalValue,
      numProperties: filtered.length
    });

    // Pass stats to parent component via setCountyStats
    setCountyStats({
      county: countyFilter,
      totalValue: totalValue,
      numProperties: filtered.length
    });

  }, [countyFilter, houses, setCountyStats]);

  if (loading && houses.length === 0) {
    return <h2>Loading...</h2>;
  }

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentRecords = filteredHouses.slice(indexOfFirstPost, indexOfLastPost);
  const howManyPages = Math.ceil(filteredHouses.length / postsPerPage);

  return (
    <div>
      <div className="housesList">
        {fetchError && <p className="text-red-500">{fetchError}</p>}

        {/* Individual County Stats Box */}
        <div className="statistics-box">
          <BsFillHousesFill size={16} />
          <strong> {statistics.numProperties}</strong>
          <RiMoneyDollarCircleFill size={16} />
          <strong>  ${formatCompactedValue(statistics.totalValue)}</strong>
        </div>

        {filteredHouses.length > 0 ? (
          <div className="house-grid">
            {currentRecords.map((house) => (
              <HouseCard key={house.id} house={house} />
            ))}
          </div>
        ) : (
          !fetchError && <p>No auctions available for {countyFilter}</p>
        )}
      </div>
      <Pagination pages={howManyPages} setCurrentPage={setCurrentPage} />
    </div>
  );
}

export default HouseList;
