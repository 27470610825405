import { useState } from "react";
import styles from "./CoinbaseButton.module.css";

const CoinbaseButton = ({ userId }) => {
  const [loading, setLoading] = useState(false);
  const [checkoutUrl, setCheckoutUrl] = useState(null); // Store the URL for the iframe

  const handlePayment = async () => {
    setLoading(true);
    try {
      const response = await fetch("https://portal-investor.pages.dev/api/coinbase/create-checkout", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ user_id: userId }),
      });

      const data = await response.json();
      if (data?.data?.hosted_url) {
        setCheckoutUrl(data.data.hosted_url); // Set the checkout URL to open in the iframe
      } else {
        console.error("Failed to create charge", data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading(false);
  };

  return (
    <>
      <button
        onClick={handlePayment}
        disabled={loading}
        className={styles["coinbase-button"]}
      >
        {loading ? "Processing..." : "Invest BTC"}
      </button>

      {/* Overlay Modal */}
      {checkoutUrl && (
        <div className={styles.overlay}>
          <div className={styles.modal}>
            <button className={styles.closeButton} onClick={() => setCheckoutUrl(null)}>✖</button>
            {/* Add sandbox attribute here */}
            <iframe
              src={`${checkoutUrl}?cachebuster=${Date.now()}`}
              className={styles.iframe}
              sandbox="allow-scripts allow-same-origin"
              title="Coinbase Checkout"
            ></iframe>
          </div>
        </div>
      )}
    </>
  );
};

export default CoinbaseButton;
