import React, { useState } from "react";
import "./Subscribe.css";
import CalendarDay from "../../Design/CalendarDay/CalendarDay";
import CheckoutPage from "../Stripe/CheckoutPage";
import { RadioData } from "../../Design/Radio/RadioData";
import Toggle from "../../Design/Toggle/Toggle";
import Icon from "../../Design/Icon/Icon";
import CheckoutButton from "../../Design/Button/Checkout/Checkout";
import CoinbaseButton from "../../Design/Button/Coinbase/CoinbaseButton";

function Subscribe() {
  const [showCheckout, setShowCheckout] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("USD"); // "USD" or "Crypto"
  const [investmentOption, setInvestmentOption] = useState(null);

  // Toggle between USD and Crypto
  const handleToggle = (newState) => {
    setPaymentMethod(newState);
  };

  // Define color based on active/inactive state for labels and icons
  const getLabelColor = (method) => {
    return paymentMethod === method ? "#001E2D" : "#D3D3D3"; // Active vs Inactive color
  };

  const fundraisingDeadline = new Date();
  fundraisingDeadline.setDate(fundraisingDeadline.getDate() + 2);

  return (
    <div className="subscribe-container">
      <div className="calendar-container">
        <span>Fundraising Round Deadline: </span>
        <CalendarDay date={fundraisingDeadline} />
      </div>

      <div className="subscribe-terms">
        <h1 className="subscribe-heading">INVESTMENT OPTIONS</h1>

        <RadioData value={investmentOption} onChange={setInvestmentOption} />

        {/* Toggle Switch for Payment Method */}
        <div className="toggle-container">
          {/* USD Icon and Label */}
          <span
            className={`toggle-label ${paymentMethod === "USD" ? "active" : "inactive"}`}
            onClick={() => handleToggle("USD")}
            style={{ display: "flex", alignItems: "center", marginRight: "1rem", color: getLabelColor("USD") }}
          >
            <Icon type="USD" size="20px" isActive={paymentMethod === "USD"} />
            <span style={{ fontSize: "1rem", marginLeft: "0.5rem" }}>USD</span>
          </span>

          {/* The Toggle Component */}
          <Toggle
            isActive={paymentMethod === "Crypto"}
            onClick={handleToggle}
            onIcon={<Icon type="USD" size="20px" isActive={paymentMethod === "USD"} />}
            offIcon={<Icon type="Crypto" size="20px" isActive={paymentMethod === "Crypto"} />}
          />

          {/* Crypto Icon and Label */}
          <span
            className={`toggle-label ${paymentMethod === "Crypto" ? "active" : "inactive"}`}
            onClick={() => handleToggle("Crypto")}
            style={{ display: "flex", alignItems: "center", marginLeft: "1rem", color: getLabelColor("Crypto") }}
          >
            <Icon type="Crypto" size="20px" isActive={paymentMethod === "Crypto"} />
            <span style={{ fontSize: "1rem", marginLeft: "0.5rem" }}>CRYPTO</span>
          </span>
        </div>

        {/* Conditionally render the correct checkout button */}
        {paymentMethod === "USD" ? (
          <CheckoutButton onClick={() => setShowCheckout(!showCheckout)} />
        ) : (
          <CoinbaseButton userId="user-123" />
        )}
      </div>

      {/* Show Checkout Page Below Subscribe */}
      {showCheckout && <CheckoutPage />}
    </div>
  );
}

export default Subscribe;
