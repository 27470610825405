// ContactInfo.js
import React from 'react';

const ContactInfo = () => {
  return (
    <div>
      <h1>Contact Information</h1>
      <p>This is the Contact Info section.</p>
    </div>
  );
};

export default ContactInfo;
