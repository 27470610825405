import React from 'react'

function NoAccess() {
  return (
    <div>
        <h2>You are not authorized to see this page.</h2>
    </div>
  )
}

export default NoAccess;