import React, { useState, useEffect } from 'react';
import HouseList from './HouseList';
import './House.css';
import { BsFillHousesFill } from "react-icons/bs";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { formatCompactedValue } from '../../../utils/formats';

function HouseViews() {
  const counties = ['citrus', 'lee', 'miamidade', 'palmbeach'];

  const [totalStats, setTotalStats] = useState({ totalAssessedValue: 0, totalProperties: 0 });
  const [countyStats, setCountyStats] = useState([]);  // You need to keep this state

  const updateCountyStats = (stats) => {
    setCountyStats((prevStats) => {
      const existingCountyStats = prevStats.filter((stat) => stat.county === stats.county);
      let updatedStats;

      if (existingCountyStats.length > 0) {
        updatedStats = prevStats.map((stat) =>
          stat.county === stats.county ? { ...stat, ...stats } : stat
        );
      } else {
        updatedStats = [...prevStats, stats];
      }

      const totalAssessedValue = updatedStats.reduce((sum, stat) => sum + stat.totalValue, 0);
      const totalProperties = updatedStats.reduce((sum, stat) => sum + stat.numProperties, 0);

      setTotalStats({ totalAssessedValue, totalProperties });

      return updatedStats;
    });
  };

  // Background effect using countyStats (e.g., logging)
  useEffect(() => {
    // Just a dummy useEffect to use the countyStats in a way that doesn't affect render
    console.log('Updated County Stats:', countyStats);

    // You can do any side effects here like saving the stats to an API, logging, etc.
  }, [countyStats]);  // This runs every time countyStats is updated.

  return (
    <div>
      <div className="market-opportunity-block">
        <h3>Market Opportunity 90 Days Outlook</h3>

        <div className="statistics-box-total">
          <h4>
            <BsFillHousesFill size={25} />
            <strong>{totalStats.totalProperties}</strong>
            <RiMoneyDollarCircleFill size={25} />
            <strong>
              {/* Format the total assessed value with compact notation */}
              ${formatCompactedValue(totalStats.totalAssessedValue)}
            </strong>
          </h4>
        </div>
      </div>

      <div className="counties-grid">
        {counties.length > 0 ? (
          counties.map((county) => (
            <div key={county} className="county-box">
              {county}
              <HouseList countyFilter={county} setCountyStats={updateCountyStats} />
            </div>
          ))
        ) : (
          <p>No counties available.</p>
        )}
      </div>
    </div>
  );
}

export default HouseViews;
