import React, { useState, useEffect } from 'react';
import './Pagination.css';

function Pagination({ pages = 10, setCurrentPage }) {
  // Set number of pages
  const numberOfPages = React.useMemo(() => {
    const pages_array = [];
    for (let i = 1; i <= pages; i++) {
      pages_array.push(i);
    }
    return pages_array;
  }, [pages]);

  const [currentButton, setCurrentButton] = useState(1);
  const [arrOfCurrButtons, setArrOfCurrButtons] = useState([]);

  useEffect(() => {
    let tempNumberOfPages = [...arrOfCurrButtons];

    let dotsInitial = '...';
    let dotsLeft = '... ';
    let dotsRight = ' ...';

    if (numberOfPages.length < 6) {
      tempNumberOfPages = numberOfPages;
    } else if (currentButton >= 1 && currentButton <= 3) {
      tempNumberOfPages = [1, 2, 3, 4, dotsInitial, numberOfPages.length];
    } else if (currentButton === 4) {
      const sliced = numberOfPages.slice(0, 5);
      tempNumberOfPages = [...sliced, dotsInitial, numberOfPages.length];
    } else if (currentButton > 4 && currentButton < numberOfPages.length - 2) {
      const sliced1 = numberOfPages.slice(currentButton - 2, currentButton);
      const sliced2 = numberOfPages.slice(currentButton, currentButton + 1);
      tempNumberOfPages = [1, dotsLeft, ...sliced1, ...sliced2, dotsRight, numberOfPages.length];
    } else if (currentButton > numberOfPages.length - 3) {
      const sliced = numberOfPages.slice(numberOfPages.length - 4);
      tempNumberOfPages = [1, dotsLeft, ...sliced];
    }

    // Avoid unnecessary state updates to prevent re-renders
    if (JSON.stringify(arrOfCurrButtons) !== JSON.stringify(tempNumberOfPages)) {
      setArrOfCurrButtons(tempNumberOfPages);
    }

    // Only update the current page if it has changed
    if (currentButton !== tempNumberOfPages[0]) {
      setCurrentPage(currentButton);
    }
  }, [currentButton, numberOfPages, arrOfCurrButtons, setCurrentPage]);

  const handlePrev = () => {
    if (currentButton > 1) {
      setCurrentButton(prev => prev - 1);
    }
  };

  const handleNext = () => {
    if (currentButton < numberOfPages.length) {
      setCurrentButton(prev => prev + 1);
    }
  };

  return (
    <div className="pagination-container">
      <button
        className={`${currentButton === 1 ? 'disabled' : ''}`}
        onClick={handlePrev}
        disabled={currentButton === 1}
      >
        Prev
      </button>

      {arrOfCurrButtons.map((item, index) => (
        <button
          key={index}
          className={`${currentButton === item ? 'active' : ''}`}
          onClick={() => setCurrentButton(item)}
        >
          {item}
        </button>
      ))}

      <button
        className={`${currentButton === numberOfPages.length ? 'disabled' : ''}`}
        onClick={handleNext}
        disabled={currentButton === numberOfPages.length}
      >
        Next
      </button>
    </div>
  );
}

export default Pagination;
