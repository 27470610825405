import React from 'react';
import './TabHome.css';
import HouseViews from '../../../Functionality/HouseCard/HouseViews';
import AboutUs from '../../../Functionality/AboutUs/AboutUs';
import Subscribe from '../../../Functionality/Subscribe/Subscribe';


function TabHome() {
  return (
    <>
    <div className="container">
        <div className="title title-1">Upcoming Investments</div>
        <div className="title title-2">Investment Round Deadline</div>
        <div className="item item-3"><HouseViews/></div>
        <div className="item item-4">
        <Subscribe />
        <AboutUs />
       

        </div>

    </div>
    </>
  )
}

export default TabHome

