import React from 'react';
import './TabDataRoom.css';
import AboutUs from '../../../Functionality/AboutUs/AboutUs';
import Subscribe from '../../../Functionality/Subscribe/Subscribe';
import DataRoom from '../../../Functionality/DataRoom/DataRoom';


function TabDataRoom() {
  return (
    <>
    <div className="container">
        <div class="title title-1">Due Diligence</div>
        <div class="title title-2">Investment Round Deadline</div>
        <div class="item item-3"><DataRoom /></div>
        <div class="item item-4"> 
        <Subscribe />
        <AboutUs />
        </div>

    </div>
    </>
  )
}

export default TabDataRoom

