import React, { useState } from 'react';
import { AccountSideBarData } from './AccountSideBarData';
import './AccountSideBar.css';
import Accreditation from '../Accreditation/Accreditation';
import ContactInfo from '../ContactInfo/ContactInfo';
import UserInfo from '../UserInfo/UserInfo';

function AccountSideBar() {
  const [activeComponent, setActiveComponent] = useState('UserInfo');  // Default component

  // Define a map for components
  const componentsMap = {
    UserInfo: <UserInfo />,
    ContactInfo: <ContactInfo />,
    Accreditation: <Accreditation />,
  };

  return (
    <div className="AccountSideBar">
      <div className="Sidebar">
        {AccountSideBarData.map((val, key) => (
          <div
            key={key}
            className="row"
            onClick={() => setActiveComponent(val.component)}  // Set active component
            id={activeComponent === val.component ? 'active' : ''}
          >
            <div id="icon">{val.icon}</div>
            <div id="title">{val.title}</div>
          </div>
        ))}
      </div>

      {/* Render the active component */}
      <div className="content">
        {componentsMap[activeComponent]}
      </div>
    </div>
  );
}

export default AccountSideBar;
