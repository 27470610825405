import React from 'react';
import CoinbaseButton from '../../../Design/Button/Coinbase/CoinbaseButton';


function TabDocuments() {
  return (
    <div>
      <h2>Testing Page</h2>
      <CoinbaseButton />
    </div>
  );
}

export default TabDocuments;
