import card1 from '../../../../../assets/card1.png';
import card2 from '../../../../../assets/card3.png';  
import card4 from '../../../../../assets/card4.png';
import card5 from '../../../../../assets/card5.png';
import card6 from '../../../../../assets/card6.png';
import card7 from '../../../../../assets/card7.png';
import { TbCoinFilled } from "react-icons/tb";
import { TbCoinBitcoinFilled } from "react-icons/tb";

const cardData = [
  {
    id: 1,
    image: card1,
    name: 'MIAMI HOME HOLDINGS INC',
    tagline: 'Preferred Stock',
    description: 'Fix & Flip Business 20% IRR',
    tags: [ 'Equity', '1.2x Multiple'],
    industry: 'Real Estate',
    amountRaised: '$5,000,000',
    valuationCap: '$50,000,000',
    profilePic: <TbCoinFilled />, 
  },
  {
    id: 3,
    image: card4,
    name: 'MIAMI HOME HOLDINGS INC',
    tagline: 'Revenue Share',
    description: 'Fix & Flip Business 20% IRR',
    tags: [ 'Debt', 'Fixed Income'],
    industry: 'Real Estate',
    amountRaised: '$5,000,000',
    valuationCap: '$50,000,000',
    profilePic: <TbCoinFilled />, 
  },
  {
    id: 5,
    image: card6,
    name: 'MIAMI HOME HOLDINGS INC',
    tagline: 'SAFE',
    description: 'Fix & Flip Business 20% IRR',
    tags: [ 'Equity', '1.2x Multiple'],
    industry: 'Technology',
    amountRaised: '$5,000,000',
    valuationCap: '$50,000,000',
    profilePic: <TbCoinFilled />, 
  },
  {
    id: 2,
    image: card2,
    name: 'MIAMI HOME HOLDINGS INC',
    tagline: 'Preferred Stock',
    description: 'Fix & Flip Business 20% IRR',
    tags: [ 'Equity', '1.2x Multiple'],
    industry: 'Real Estate',
    amountRaised: '$10,000,000',
    valuationCap: '$100,000,000',
    profilePic: <TbCoinBitcoinFilled />, 
  },
  {
    id: 4,
    image: card5,
    name: 'MIAMI HOME HOLDINGS INC',
    tagline: 'Revenue Share',
    description: 'Fix & Flip Business 20% IRR',
    tags: [ 'Debt', 'Fixed Income'],
    industry: 'Real Estate',
    amountRaised: '$10,000,000',
    valuationCap: '$100,000,000',
    profilePic: <TbCoinBitcoinFilled />, 
  },
  {
    id: 6,
    image: card7,
    name: 'MIAMI HOME HOLDINGS INC',
    tagline: 'Preferred Stock',
    description: 'Fix & Flip Business 20% IRR',
    tags: [ 'Equity', '1.2x Multiple'],
    industry: 'Real Estate',
    amountRaised: '$10,000,000',
    valuationCap: '$100,000,000',
    profilePic: <TbCoinBitcoinFilled />, 
  },
];

export default cardData;
