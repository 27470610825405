import React from 'react';
import styles from './Radio.module.css'; // Import the CSS module

function RadioGroup({ name, items, value, onChange }) {
  return (
    <div className={styles.radioGroup}> {/* Container for the radio buttons */}
      {items.map((item) => (
        <div key={item.value} className={styles.radioOption}> {/* Each radio option */}
          <p className={styles.radioItemWrapper}>
            <input
              type="radio"
              id={name + item.value}
              name={name}
              value={item.value}
              checked={value === item.value}
              onChange={(e) => onChange(e.target.value)}
            />
            <label htmlFor={name + item.value}>
              {item.label}
            </label>
          </p>
        </div>
      ))}
    </div>
  );
}

export default RadioGroup;
