import React, { useState } from 'react';
import { FaFolder, FaFile } from 'react-icons/fa'; // Import icons

function FilesFolders() {
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);

  const dataRoomFolders = [
    {
      folderName: 'Legal',
      files: [
        { name: 'Self-Accreditation', url: 'https://drive.google.com/file/d/1IK72Zzqrym-1TO52SqkoZh-GI4iHcoRa/preview', content: 'Self Accreditation Questionaire' },
      ],
    },
    {
      folderName: 'Business',
      files: [
       
        { name: 'Business Plan', url: 'https://drive.google.com/file/d/1j32wczHhxvtewvFzN67eW_0gtenFKXPO/preview', content: 'Business Plan' },
        { name: 'Our Team', url: 'https://drive.google.com/file/d/1oJCgNVD5451mCJNorjJCkiLGmpE6oiJN/preview', content: 'Our Team' },
        // { name: 'Investor Terms', url: 'https://drive.google.com/file/d/19lKXjLvkN3Qxh-zjoc91faXMh0ksuGV3/preview', content: 'Investor Terms' },
        { name: 'Cap Table', url: '#', content: 'Cap Table' },
      ],
    },
  ];

  const handleFileClick = (url) => {
    setSelectedFileUrl(url);
  };

  return (
    <div className="data-room-container">
      <div className="data-room-content">
        <div className="folder-section">
          {dataRoomFolders.map((folder) => (
            <div key={folder.folderName} className="folder-container">
              <div className="folder-heading">
                <FaFolder style={{ marginRight: '10px' }} /> {/* Folder icon */}
                {folder.folderName}
              </div>
              <ul className="file-list">
                {folder.files.map((file) => (
                  <li
                    key={file.name} // Use file name as the key
                    className="file-item"
                    onClick={() => handleFileClick(file.url)} // Pass URL to parent when clicked
                  >
                    <FaFile style={{ marginRight: '10px' }} /> {/* File icon */}
                    {file.name}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Display PDF Preview here */}
        <div className="pdf-viewer">
          {selectedFileUrl ? (
            <iframe
              src={selectedFileUrl} // Use the selected URL
              width="100%"
              height="500px"
              frameBorder="0"
              title="Google Drive PDF"
            ></iframe>
          ) : (
            <p>Select a file to preview.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default FilesFolders;
