import React from 'react';
import styles from './Toggle.module.css'; // Importing styles from the CSS module

const Toggle = ({ isActive, onClick, onIcon, offIcon }) => {
  

  return (
    <div className={styles['btn-holder']} onClick={onClick}>
      <div className={`${styles['btn-circle']} ${isActive ? styles.active : ''}`} />
      <input type="checkbox" className={styles.checkbox} checked={isActive} readOnly />
    </div>
  );
};

export default Toggle;
