import React, { useState } from 'react';
import { verifyEmail } from '../../../utils/auth.controller';
import './Login.css'; 

function VerifyEmail() {
  const [code, setCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Handle change in the verification code input field
  const handleCodeChange = (event) => {
    const enteredCode = event.target.value;
    setCode(enteredCode);

    // Clear previous messages
    setErrorMessage('');
    setSuccessMessage('');
  };

  // Verify the code when the user clicks the 'Verify Code' button
  const handleVerifyClick = async () => {
    try {
      // Input validation: Ensure the code is exactly 6 digits
      if (!/^\d{6}$/.test(code)) {
        setErrorMessage('Please enter a valid 6-digit verification code.');
        return;
      }

      // Call the verifyUserEmail function from auth.controller.js
      await verifyEmail(code);

      // If the verification is successful, display success message
      setTimeout(() => {
        window.location.href = '/login'; 
    }, 10);
      
    } catch (error) {
      // Error handling: Display the error message
      console.error('Error occurred:', error.message); // Log the full error for debugging
      setErrorMessage(error.message);
    }
  };

  return (
    <div className="login-page">
      <div className="login-image"></div> {/* You can add a background image if needed */}

      {/* Right Side Form */}
      <div className="login-form-container">
        <div className="login-form">
          <h2>Verify Your Email</h2>
          
          {/* Display success or error message */}
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          {successMessage && <p className="success-message">{successMessage}</p>}

          <form>
            <div className="form-group">
            <div className="input-wrapper">
            <i className="fa fa-unlock"></i>
              <input
                type="text"
                id="code"
                name="code"
                value={code}
                onChange={handleCodeChange}
                maxLength="6"
                placeholder="Enter code"
                required
              />
            </div>
            </div>

            <button
              type="button"
              onClick={handleVerifyClick} // Button now triggers verification logic
            >
              Verify Code
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default VerifyEmail;
