import './Portal.css';
import { Tabs, Tab, TabList, TabPanel } from '../../components/TabsAll/Tabs/Tabs';
import TabHome from '../../components/TabsAll/Portal/TabHome/TabHome';
import TabDataRoom from '../../components/TabsAll/Portal/TabDataRoom/TabDataRoom';
import TabInvestments from '../../components/TabsAll/Portal/TabInvestments/TabInvestments';
import TabDocuments from '../../components/TabsAll/Portal/TabDocuments/TabDocuments';
import TabMarket from '../../components/TabsAll/Portal/TabMarket/TabMarket';
import { Link } from 'react-router-dom'; 
import { CgProfile } from "react-icons/cg";


function Portal() {
  
  return (
    <Tabs>
      <TabList>
        <Tab index={0}>offers</Tab>
        <Tab index={2}>deal flow</Tab> 
        <Tab index={3}>data room</Tab>
        <Tab index={4}>investments</Tab>
        <Tab index={5}>documents</Tab>
        <Tab index={6}><Link to="/account" style={{ textDecoration: 'none', color: 'inherit' }}> <CgProfile size={14} /></Link>
        </Tab>

      </TabList>

      <TabPanel index={0}><TabMarket/></TabPanel>
      <TabPanel index={2}><TabHome/></TabPanel>
      <TabPanel index={3}><TabDataRoom /></TabPanel>
      <TabPanel index={4}><TabInvestments /></TabPanel>
      <TabPanel index={5}><TabDocuments /></TabPanel>
    </Tabs>
  );
}

export default Portal;
