import { SignJWT, jwtVerify } from 'jose';

export const refreshJwtAndSetCookie = async (user, setUser) => {
    try {
        // Extensive logging to understand the user object
        console.log('User object received:', JSON.stringify(user, null, 2));
        console.log('Detailed user properties:');
        console.log('user.user_id:', user.user_id);
        console.log('user.email:', user.email);
        console.log('user.role:', user.role);
        console.log('user.name:', user.name);

        // Validate required fields
        if (!user.user_id) {
            console.error('Missing user_id in user object');
            throw new Error('User ID is required to generate token');
        }

        // Create a new JWT token with the user's updated role and other info
        const token = await new SignJWT({
            user_id: user.user_id,  // Explicitly use user_id
            email: user.email,
            role: user.role,
            name: user.name
        })
        .setExpirationTime('30d')
        .setProtectedHeader({ alg: 'HS256' })
        .sign(new TextEncoder().encode('451735'));

        // More robust cookie setting
        const maxAge = 30 * 24 * 60 * 60 * 1000; // 30 days
        const cookieOptions = `SameSite=Strict; Max-Age=${maxAge}; Path=/; Secure`; // Added Secure flag

        // Set the new cookie in the browser
        document.cookie = `token=${token}; ${cookieOptions}`;

        console.log('Token generated and cookie set:', token);

        // Optional: Verify the token immediately after creation
        try {
            const { payload } = await jwtVerify(
                token, 
                new TextEncoder().encode('451735')
            );
            console.log('Decoded Token Payload:', payload);
        } catch (verificationError) {
            console.error('Token verification failed:', verificationError);
        }

        // Update the user context 
        setUser(prevUser => {
            console.log('Previous user state:', prevUser);
            const newUserState = {
                ...prevUser,
                role: user.role,
                // Optionally add more fields if needed
                id: user.user_id,
                email: user.email,
                name: user.name
            };
            console.log('New user state:', newUserState);
            return newUserState;
        });

        return token;
    } catch (error) {
        console.error('Error details:', error);
        console.error('Error stack:', error.stack);
        throw new Error(`Failed to generate token or set cookie: ${error.message}`);
    }
};