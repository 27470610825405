import React, { useState } from 'react'; // Import useState
import Card from './Card/Card'; // Import Card
import CardDetail from './CardDetail/CardDetail'; // Import CardDetail

const TabMarket = () => {
  const [selectedCard, setSelectedCard] = useState(null); // State to hold the selected card's data

  const handleCardClick = (card) => {
    setSelectedCard(card); // Set the selected card when a card is clicked
  };

  return (
    <div className="container">
      <div className="title title-1">Investment Opportunities</div>
      <div className="title title-2">Details</div>

      <div className="investment-cards">
        <Card onCardClick={handleCardClick} /> {/* Pass the handleCardClick function to the Card component */}
      </div>

      <div className="card-detail">
        {selectedCard ? (
          <CardDetail investment={selectedCard} /> // Pass selectedCard as investment to CardDetail
        ) : (
          <p>Click on a deal to see details</p> // Show a prompt if no card is selected
        )}
      </div>
    </div>
  );
};

export default TabMarket;
