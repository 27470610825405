import React, { useState, useEffect } from "react";
import { login } from "../../../utils/auth.controller";
import { useAuth } from "../../../context/AuthContext"; // Import the AuthContext to access user data
import "./Login.css";

const Login = () => {
  const { user, setUser, setIsAuthenticated } = useAuth(); // Access user data from context
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Clear input fields on component mount
  useEffect(() => {
    setEmail("");
    setPassword("");
  }, []);

  // Add a useEffect to handle redirect after the user data is updated
  useEffect(() => {
    if (user && user.role) {
      console.log("User data in effect:", user); // Debug log
      setSuccessMessage("Login successful! Redirecting...");
      
      // Once the user data is updated in context, redirect based on role
      setTimeout(() => {
        if (user.role === "admin") {
          window.location.href = "/admin"; // Make sure this matches your route
        } else if (user.role === "investor") {
          window.location.href = "/portal";
        } else if (user.role === "fundraiser") {
          window.location.href = "/sponsor";
        } else if (user.role === "user") {
          window.location.href = "/account";
        } else {
          window.location.href = "/verify-email"; // Default redirect if no role matched
        }
      }, 500); // Increased timeout to ensure state is fully updated
    }
  }, [user]); // This will trigger whenever the user changes (like after login)

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(""); // Clear any error messages
    setSuccessMessage(""); // Clear any success messages
    setIsLoading(true);

    try {
      // Perform the login operation
      const response = await login({ email, password });
      console.log("Login response:", response); // Debug log
      
      // Make sure we're getting user data from the response
      if (!response || !response.user) {
        // If login doesn't return user data, we need to fetch it separately
        // or ensure the login function returns the proper user data
        console.error("Login successful but no user data returned");
        setErrorMessage("Login successful but unable to redirect. Please try again.");
        setIsLoading(false);
        return;
      }
      
      // Set user data in context
      setUser(response.user);
      setIsAuthenticated(true);
      
      // Show success message (redirect will happen in useEffect)
      setSuccessMessage("Login successful! Redirecting...");
    } catch (err) {
      // Log and display error
      console.error("Error during login:", err);
      setErrorMessage(err.message || "Something went wrong. Please try again later.");
      setIsLoading(false);
    }
  };

  const handleForgotPassword = () => {
    setTimeout(() => {
      window.location.href = "/forgot-password"; 
    }, 10);
  };

  return (
    <div className="login-page">
      <div className="login-form-container">
        <div className="login-form">
          <h2>Login</h2>
          
          {successMessage && <p className="success-message">{successMessage}</p>}
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <div className="input-wrapper">
                <i className="fa fa-envelope"></i>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  autoComplete="new-email" 
                  required
                />
              </div>
            </div>

            <div className="form-group">
              <div className="input-wrapper">
                <i className="fa fa-lock"></i>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  autoComplete="new-password"
                  required
                />
              </div>
            </div>
            
            <button type="submit" disabled={isLoading}>
              {isLoading ? "Logging in..." : "Login"}
            </button>
          </form>

          <div className="forgot-password-container">
            <button
              type="button"
              className="forgot-password-link"
              onClick={handleForgotPassword}
            >
              Forgot Password?
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;