import React from 'react';
// import styles from './Reports.module.css';
// import { useAuth } from '../../../../context/AuthContext';  
import Dashboard from '../../../Functionality/Dashboard/Dashboard';
import Sidebar from '../../../Functionality/Sidebar/Sidebar';

function Reports() {

  return (
    <div className="container">
        <div className="title title-1">Your Reports</div> 
        <div className="title title-2">Dashboard</div>
        <div className="item item-3"><Sidebar /></div>
        <div className="item item-4"><Dashboard /></div>
    </div>
  );
}

export default Reports