import React from "react";
import CalendarIcon from "react-calendar-icon";

export default function CalendarDay({ date }) {
  return (
    <div>
      <CalendarIcon
        date={date}
        options={{
          header: { month: "long" },
          value: { day: "2-digit" },
          locale: "en-US"
        }}
      />
    </div>
  );
}