import React, { createContext, useState, useContext, useEffect } from 'react';
import { checkAuth } from '../utils/auth.controller';  

const AuthContext = createContext(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const authenticateUser = async () => {
      try {
        const result = await checkAuth();  // Your custom function to check auth
        if (result.isAuthenticated) {
          setUser(result.user);
          setIsAuthenticated(true);
        } else {
          setUser(null);
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Authentication check failed:', error);
        setUser(null);
        setIsAuthenticated(false);
      } finally {
        setLoading(false);  // Stop loading after the check is done
      }
    };

    authenticateUser();  // Check the authentication status on mount

    // Optionally clean up if needed
    return () => {
      setLoading(true);  // Reset loading state if needed
    };
  }, []); // Empty dependency array ensures this runs once when the component mounts

  const value = {
    user,  // The authenticated user's information
    isAuthenticated,
    loading,
    setUser,
    setIsAuthenticated
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};
