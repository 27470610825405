import React from 'react';
import styles from './Checkout.module.css';

const CheckoutButton = ({ onClick }) => {
  return (
    <button className={styles['checkout-button']} onClick={onClick}> 
      Invest $
    </button>
  );
};

export default CheckoutButton;
